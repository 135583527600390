import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getFocus, useAtom, useGet, useSet } from "state/jotai";
import { toggle_drawer } from "state/actions";
import {
  active_project,
  filter_State,
  isMulti_project_active,
  iso_free,
  iso_isrotating,
  params_query,
  previousUrl,
  turn_building,
  vis_desktop_nav,
  vis_mobile_nav,
} from "state/store.global";
import { favorites } from "state/store.global";
import { vis_compare } from "state/store.global";
import { compares } from "state/store.global";
import { useFetch_Colors } from "hooks/fetch/useFetch_Colors";
import { useFetch_Navigation } from "hooks/fetch/useFetch_Navigation";
import { Icon } from "assets/Icons_";
import { Logo } from "./Nav.Logo";
import { Foldout } from "./Nav.Mobile.Foldout";
import { useTranslation } from "react-i18next";
    // import LanguageMenu              from 'components/LanguageMenu/LanguageMenu'

import { Menu, Gap, Section, Text, BackBox, CarratLeft, CarratRight } from "./Nav.Mobile.style";

import NavItems from "./NavItems";
import { useBreak } from "hooks/useBreak";
import { Box } from "@mui/material";
import { _bootstrap } from "state/store.bootstrap";
import { ShiftProperty } from "components/ShiftProperty/ShiftProperty";
import { getIsoData } from "state/jotai.isometryDataHooks";
import { _project } from "state/store.projects";
import { BackButton } from "components/Button/BackButton";
import { ETStyledButton } from "components/Button/EusButton";

export const NavMobile = () => {
  const here         = useLocation().pathname;
  const isMobile     = useBreak("md_dn");
  const { pin, pid } = useGet(active_project);
  const config       = getIsoData("config");

  const { clr }         = useFetch_Colors();
  const { nav }         = useFetch_Navigation();
  const [show, setShow] = useAtom(vis_mobile_nav);
  const buttonsLabel    = getFocus(_project, "buttonsLabel")?.[pin]?.[pid] ?? [];
  
  const {
    i18n: { language },
  } = useTranslation();

  const [state, setState] = useState();
  const [filterState, setFilterState] = useAtom(filter_State);
  const [, setShowMenu]   = useAtom(vis_desktop_nav);
  const [url]             = useAtom(previousUrl);

  const navigateTo   = useNavigate();
  const params       = useGet(params_query)
  const toggleDrawer = useAtom(toggle_drawer)[1];
  const comptoggle   = useAtom(vis_compare)[1];
  const clickHelp    = () => {
    navigateTo(`${language}/help${params.length ? params : ''}`);
    toggleDrawer();
  };
  const clickFavs = () => {
    navigateTo(`${language}/favorites${params.length ? params : ''}`);
    toggleDrawer();
  };
  const clickComp = () => {
    comptoggle((a) => !a);
  };
  const click = () => {
    setShow(show ? false : true);
  };

  const compCount = useAtom(compares)[0].length;
  const likeCount = useAtom(favorites)[0].length;

  const heart = {
    color: clr.icons,
    icon : "heart7",
    size : 28,
    count: likeCount,
  };
  const comp = {
    color: clr.icons,
    icon : "compare3",
    size : 28,
    count: compCount,
  };
  const bars  = { color: clr.icons, icon: "three_bars", size: 33, click };
  const close = { color: clr.icons, icon: "close1", size: 25, click };
  const globe = {
    color: clr.icons,
    icon : "globe",
    size : 28,
    click: () => setState(!state),
  };
  const glarr = {
    color: clr.icons,
    icon : "globe_arrow",
    size : 10,
    click: () => setState(!state),
    wrap : {
      position : "absolute",
      bottom   : -8,
      right    : -10,
      transform: state ? "rotate(180deg)": "rotate(0deg)",
    },
  };

  const projectType                       = getFocus(_bootstrap, "type");
  const atModel                           = here.includes("isometry");
  const atList                            = here.includes("list");
  const atUnit                            = here.includes("unit");
  const atFavorites                       = here.includes("favorites");
  const atCompare                         = here.includes("compare");
  const atDownloads                       = here.includes("downloads");
  const atGallery                         = here.includes("gallery");
  const multiActive                       = useAtom(isMulti_project_active)[0];
  const [turnBuilding, setIsTurnBuilding] = useAtom(turn_building);

  const rotatemode    = useGet(iso_free);
  const setRotatemode = useSet(iso_free);
  const isrotate      = useGet(iso_isrotating);
  const setIsrotate   = useSet(iso_isrotating);

  const [showProperty, setShowProperty] = useState(false);

  const hideFilterMultiActiveTrue = [
    "compare",
    "gallery",
    "downloads",
    "unit",
  ].some((i) => here.includes(i));

  const hideFilterMultiActiveFalse = [
    "compare",
    "gallery",
    "downloads",
    "unit",
  ].some((i) => here.includes(i));

  const filterShouldShow = !multiActive
    ? !hideFilterMultiActiveTrue
    :   !hideFilterMultiActiveFalse;

  const showButton = [
    "list",
    "compare",
    "favorites",
    "gallery",
    "downloads",
  ].some((i) => here.includes(i));

  const filter = {
    size : "lg",
    icon : "filters",
    color: "#000",
  };

  const clickFilter = () => {
    if (!filterState) {
      // When filter is being opened, keep the focus
      toggleDrawer("filter");
      setFilterState(true);
      setShowMenu(false);
      // setShowProperty(false);
    } else {
      // When filter is already open, remove focus
      setFilterState(false);
      toggleDrawer("filter");
      const buttons = document.querySelectorAll('button');
      buttons.forEach(button => button.blur());
    }
  };
  const click1 = () => {
    setIsrotate(false);
    setIsTurnBuilding(true);
  };

  const click2 = () => {
    setRotatemode(true);
    setIsrotate(true);
    setIsTurnBuilding(true);
  };
  return (
    <>
      <Foldout nav = {nav} show = {show} setShow = {setShow} />
      {/* <LanguageMenu state={state} setState={setState}/> */}
      <Menu clr = {clr}>
        <span>
          <span style  = {{paddingLeft:"16px" , position:"relative"}}>
          <Logo device = "mobile" link = "https://gammelhavn.nu/" />
          </span>
          {
            (
              atCompare ||
              atFavorites ||
              atDownloads ||
              atGallery) && (
                <BackButton color = {`${clr?.primary_text_passive}`}/>
            )}
        </span>
        {(atModel || atList) && (
          !(rotatemode && pin > 0) && (projectType === 'poly') && (
            <Box
              sx = {{ position: "fixed", top: "70px", left: "0px", width: "100%" }}
            >
              <Box
                sx={{
                  padding        : "16px 16px",
                  display        : "flex",
                  justifyContent : "space-between",
                  gap            : "8px",
                  backgroundColor: "#EFEFEF",
                  borderRadius   : "6px 6px 0px 0px"
                }}
              >
                
                {filterShouldShow && isMobile && (
                  <ETStyledButton
                  tabIndex={"0"}
                         role="button"
                 variant={"secondary"}
                 padding={"12px 28px"}
                   pin        = {pin}
                   clr        = {clr}
                   showButton = {showButton}
                   onClick    = {clickFilter}
                 >
                   <Icon {...filter} isFaIcon = {true} skipColorChange={true} />
                   <span className="paragraph1-regular" style={{color:"#000"}}>
                   {
                     buttonsLabel?.filter(
                       (btn) => btn.UILabelProperty === "Anvendfilter"
                     )[0]?.Label || "Anvend filter"
                   }
                   </span>
                 </ETStyledButton>
                )}
                {config?.IMAGE_SNAPFRAMES?.length > 0 && atModel && (
                  <Section
                    pin     = {pin}
                    clr     = {clr}
                    onClick = {rotatemode && isrotate ? click1 : click2}
                  >
                    <>
                      {!turnBuilding ? (
                        <>
                          <Icon
                            icon     = "rightarrowrotate"
                            color    = {"#000"}
                            isFaIcon = {true}
                            size     = "lg"
                          />{" "}
                          {
                            buttonsLabel?.filter(
                              (btn) => btn?.UILabelProperty === "Drejbygning"
                            )[0]?.Label
                            || "Drej bygning"
                          }
                        </>
                      ) : (
                        <>
                          <Icon
                            icon     = {isrotate ? "pause" : "play"}
                            color    = {"#000"}
                            isFaIcon = {true}
                            size     = "lg"
                          />{" "}
                          {isrotate ? "Stop" : "play"}
                        </>
                      )}
                    </>
                  </Section>
                )}
              </Box>
            </Box>
          )
        )}
            { ((isMobile && (pin !== 0 || !atModel)) || projectType === "mono") && atModel && <Box sx={{ width: '100%', height: '20px', background: 'white', position: 'absolute', left: '0', bottom: '-16px', borderRadius: '6px 6px 0 0' }}></Box>}
        {
          (atModel || atList) && projectType === 'mono' && !rotatemode && (
            <Box sx = {{ position: "fixed", top: "70px", left: "0px", width: "100%" }}>
              <Box
                sx={{
                  padding        : "16px 16px",
                  display        : "flex",
                  justifyContent : "space-between",
                  gap            : "8px",
                  backgroundColor: "#EFEFEF",
                  borderRadius   : "6px 6px 0px 0px"
                }}
              >
                  <CarratLeft></CarratLeft>
                  <CarratRight></CarratRight>
                {filterShouldShow && isMobile && (
                 <ETStyledButton
                 variant={"secondary"}
                 padding={"12px 28px"}
                   pin        = {pin}
                   clr        = {clr}
                   showButton = {showButton}
                   onClick    = {clickFilter}
                 >
                   <Icon {...filter} isFaIcon = {true} skipColorChange={true}/>
                   <span className="eus-button-2">
                   {
                     buttonsLabel?.filter(
                       (btn) => btn.UILabelProperty === "Anvendfilter"
                     )[0]?.Label || "Anvend filter"
                   }
                   </span>
                   { atList &&
                     <>
                     <CarratLeft></CarratLeft>
                     <CarratRight></CarratRight>
                     </>
                   }
                 </ETStyledButton>
                )}
                {config?.IMAGE_SNAPFRAMES?.length > 0 && atModel && (
                  <ETStyledButton
                  variant ={"secondary"}
                    pin     = {pin}
                    clr     = {clr}
                    onClick = {rotatemode && isrotate ? click1 : click2}
                    padding ={"12px 28px"}
                   
                  >
                    <>
                      {!turnBuilding ? (
                        <>
                          <Icon
                            icon     = "rightarrowrotate"
                            color    = {"#000"}
                            isFaIcon = {true}
                            size     = "xl"
                            skipColorChange={true}
                          />{" "}
                          <span className="eus-button-2">

                         
                          {
                            buttonsLabel?.filter(
                              (btn) => btn?.UILabelProperty === "Drejbygning"
                            )[0]?.Label
                            || "Drej bygning"
                          }
                           </span>
                        </>
                      ) : (
                        <>
                          <Icon
                            icon     = {isrotate ? "pause" : "play"}
                            color    = {"#000"}
                            isFaIcon = {true}
                            size     = "xl"
                          />{" "}
                           <span className="eus-button-2">
                          {isrotate ? "Stop" : "play"}
                          </span>
                        </>
                      )}
                    </>
                  </ETStyledButton>
                )}
              </Box>
            </Box>
          )
        }
        <NavItems hideNav = {show}/>

        <span style = {{paddingRight:"16px" , position:"relative"}}>
            <Icon {...bars}/>
        </span>
        { 
          isMobile && (projectType === 'mono' || pin > 0) && !(atModel || atList) && <>
          <CarratLeft  bgColor = {clr.primary} shadowBg = {'white'}></CarratLeft>
          <CarratRight bgColor = {clr.primary} shadowBg = {'white'}></CarratRight>
        </>
        }
      </Menu>
    </>
  );
};
